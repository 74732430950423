import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import promptpay from 'promptpay-qr';
import QRCode from 'qrcode.react';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';

function CopyButton({ totalAmount }) {
    const accountNumber = "1761696374";
    const textRef = useRef(null);

    const handleCopy = () => {
        const textarea = textRef.current;
        textarea.select();
        document.execCommand('copy');
        Swal.fire(`คัดลอก 1761696374 แล้ว`, `ยอดชำระ ${totalAmount}`, 'primary');
    };

    return (

        <>

            <textarea 
                ref={textRef}
                value={accountNumber}
                style={{ position: 'absolute', left: '-1000px', top: '-1000px' }}
            />
            <button className='btnc' onClick={handleCopy}>คัดลอก</button>
        </>
    );
}

function ReceiptTable({ items }) {
    const totalAmount = items.reduce((sum, item) => sum + item.price + item.value, 0);

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th id='a'>รายละเอียดบริการ</th>
                    <th></th>
                    <th id='b'>ราคา</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td>{item.name}</td>
                            <td></td>
                            <td>{item.value}</td>
                        </tr>
                        <tr>
                            <td>{item.quantity}</td>
                            <td></td>
                            <td>{item.price}</td>
                        </tr>
                    </React.Fragment>
                ))}
                <tr>
                    <th>ยอดรวม ชำระ</th>
                    <th></th>
                    <th>{totalAmount} บาท</th>
                </tr>
            </tbody>
        </table>
    );
}

const Receipt = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [amount, setAmount] = useState(10);
    const phoneNumber = '0956422872';
    

    useEffect(() => {
        fetch("https://ads.service-ads.com/api")
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setItems(data);
            setLoading(false);
        })
        .catch(error => {
            setError(error);
            setLoading(false);
        });
    }, []);

    const handlePrintPage = () => {
        window.print();
    };

    const printReceipt = () => {
        const input = document.getElementById('box');
    
        // Assuming 'items' state holds the customer's details and you want to use the first customer's name
        const customerName = items.length > 0 ? items[0].customer : 'default';
    
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.download = `${customerName}.png`; // Setting the download name as the customer's name
            link.href = imgData;
            link.click();
        });
    };
    
   
    



    const totalAmount = items.reduce((sum, item) => sum + item.price + item.value, 0);
    let payload = amount ? promptpay(phoneNumber, { amount: parseFloat(totalAmount) }) : promptpay(phoneNumber);
 
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;



    return (


        <div id="box">



            <header>
                <h1><img src="logo.png" width={70} alt="" /> <br /> Ads manager </h1>
                <p>Ads Manager 32 Soi 20 Charoenrat Road,<br /> Khlong San District, Bangkok 10160</p>
                <p>โทรศัพท์: 0956650050</p>
            </header>

            <table className="table table-hover">
                {items.map((item, index) => (
                    <div key={index}>
                       
                    
                        <tr>
                            <th id='a'>รายละเอียดบริการ</th>
                            <th></th>
                            <th id='b'>ราคา</th>
                        </tr>

                        <tr>
                            <td>{item.name}</td>
                            <td></td>
                            <td>{item.value}</td>
                        </tr>

                        <tr>
                            <td>{item.quantity}</td>
                            <td></td>
                            <td>{item.price}</td>
                        </tr>

                        <tr>
                            <th>ยอดรวม ชำระ</th>
                            <th></th>
                            <th>{totalAmount} บาท</th>
                        </tr>

                        <main>
                            <h3>ลูกค้า : คุณ {item.customer}</h3>
                        </main>
                    </div>
                ))}
            </table>

            <div>
                <input 
                    type="hidden" 
                    placeholder="Enter Amount" 
                    value={totalAmount}
                    onChange={(e) => setAmount(e.target.value)} 
                />

                <div id="qra">
                    <div id="qrb">
                        <img src="p.jpg" width={180} alt="" /><br />
                        <QRCode value={payload} size={180} />
                    </div>
                    <div id="qrc">
                   
                        <div id="bang"> 
                        <img src="name.png" width='100%' alt="" />

                        <div className="text-center">  
                        <CopyButton totalAmount={totalAmount} /></div>
                         </div>
                    </div>
                </div>
            </div>

            <footer>
                <p>ขอบคุณที่ใช้บริการ</p>
                <p>วันที่: {new Date().toLocaleDateString()}</p>
            </footer>

            <button onClick={handlePrintPage}>พิมพ์</button>
            <button onClick={printReceipt}>บันทึกเป็นรูปภาพ</button>
        </div>
    );
}

export default Receipt;
