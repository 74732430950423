import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import './App.css';

function Admin() {
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        value: '',
        quantity: '',
        price: '',
        customer: '',
    });

    // Fetch data from the API
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await Axios.get('https://ads.service-ads.com/api');
          setData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
    

    // Handle form input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Submit form data
   // Submit form data
   const handleSubmit = (e) => {
    e.preventDefault();
  
    Axios.post("https://ads.service-ads.com/Add", formData)
      .then(response => {
        Swal.fire({
          title: 'Success',
          text: 'ข้อมูลถูกเพิ่มเรียบร้อยแล้ว',
          icon: 'success'
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            window.location.reload();
          }
        });
        setFormData({ name: '', value: '', quantity: '', price: '', customer: '' }); // Reset form
      })
      .catch(error => {
          Swal.fire('Error', 'There was a problem adding the data', 'error');
          console.error('Error posting data:', error);
      });
  };
  
  




  const handleDelete = (id) => {
    Axios.delete(`https://ads.service-ads.com/delete/${id}`)
      .then(() => {
        Swal.fire({
          title: 'Deleted!',
          text: 'Your data has been deleted.',
          icon: 'success'
        }).then((result) => {
          if (result.isConfirmed || result.dismiss) {
            window.location.reload();
          }
        });
      })
      .catch(error => {
          Swal.fire('Error', 'There was a problem deleting the data', 'error');
          console.error('Error deleting data:', error);
      });
  };
  

  
  return (
    <div className="App">
     <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
       
        </tr>
        </thead>
      <tbody class="alert alert-primary">
     
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.value}</td>
            <td>{item.quantity}</td>
            <td>{item.price}</td>
            <td>{item.customer}</td>
            <td>
              <button onClick={() => handleDelete(item.id)}>Delete</button>
            </td>
          </tr>
        ))}
       
      </tbody>
    </table>



      <h1>Add Data</h1>
      <form onSubmit={handleSubmit}>
      <div className='inputa'/> 
      <div class="input-group input-group-sm">
     

        <input type="text" id="name" list='atext' class="form-control m-3" name="name" value={formData.name} onChange={handleChange}placeholder="รายการ" />
   
   
        <input type="text" id="value" list='b' name="value" class="form-control m-3" value={formData.value} onChange={handleChange} placeholder="บาท" />
     
        </div>
        <div class="input-group input-group-sm">
      

        <input type="text" id="quantity" list='c' class="form-control m-3" name="quantity" value={formData.quantity} onChange={handleChange} placeholder="รายการ" />
       
        <input type="text" id="price" name="price" class="form-control m-3" value={formData.price} onChange={handleChange} placeholder="บาท" />
      
        </div>
        <div class="input-group input-group-sm">            
        <input type="text" id="customer" name="customer" class="form-control m-3" value={formData.customer} onChange={handleChange} placeholder="ชื่อลูกค้า" />
        


        </div>
        <input type="submit" className='btn btn-success' value="Add Data" />
        <br/>
       
      </form>

  
      <div className="App">
   
      <datalist id="atext">
        <option value="คอร์สเรียนทำโฆษณาออนไลน์ Google Ads สายเทา"></option>
      <option value="คอร์สเรียนทำโฆษณาออนไลน์ facebook Ads สายเทา"></option>
      <option value="บริการทำโฆษณา Google Ads สายเทา รายเดือน">
        </option><option value="บริการทำโฆษณา facebook Ads สายเทา รายเดือน">
          </option><option value="บริการทำโฆษณา Google Ads สายเทา รายเดือน"></option>
          <option value="บัญชีโฆษณา Google Ads คีย์เทา อุธรแล้ว"></option>
          </datalist>
     
     
      <datalist id="b"><option value="9900"></option><option value="4950"></option><option value="18500"></option><option value="9250"></option></datalist>
      <datalist id="c"><option value="ค่าโฆษณาที่ต้องจ่ายให้กับ Google (งบยิง) วัน x บาท"></option><option value="ค่าโฆษณาที่ต้องจ่ายให้กับ facebook (งบยิง) วัน x บาท"></option><option value="ค่าบริการ"></option></datalist>


     

  </div>
  
    </div>



  );
  }

export default Admin;
