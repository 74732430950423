import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // ตั้งแต่คุณใช้ create-react-app, นี่คือไฟล์สไตล์หลัก
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
