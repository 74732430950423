import {
  BrowserRouter, Routes,
  Switch,
  Route,
  // ... other imports
} from 'react-router-dom';
import React from 'react'
import Receipt from './Receipt'
import Login from './Login'



function App() {
  return (
<> 
    <p id='img'></p>

   <BrowserRouter>
    <Routes>
      
    <Route path="/" element={<Receipt/>}/>
    
  <Route path="/admin" element={<Login/>}/>
  
    </Routes>
  
   </BrowserRouter>
    </>  
  )
}

export default App
