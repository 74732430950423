import React, { useState } from 'react';
import './index.css';
import Receipt from './Receipt'
import Admin from './Admin'
import { Link } from 'react-router-dom';
function Login() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  
  // รับค่าจาก localStorage และแปลงเป็น boolean เพื่อตั้งค่าเริ่มต้น
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  const handleLogin = () => {
    if (userId === 'admin' && password === '1122') {
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
    } else {
      alert('Incorrect ID or Password!');
    }
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  }

  if (isLoggedIn) {
    return <div> 
        <div className='text-center'><Link Link to="/"className='btn btn-info '  >Home</Link></div>
        <Receipt/>  <Admin/> </div>;
  }

  return (
    <div className='from'>
      <div id='boxx' className="shadow p-3 mb-5 bg-body-tertiary rounded">
        <label>
          ID:
          <input
            type="text"
            className="form-control"
            value={userId}
            onChange={e => setUserId(e.target.value)}
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
        <br />
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
